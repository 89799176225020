<template>
  <div class="contents userMgt posts" v-loading="loading">
    <div class="title flexB">
      <h2>설문 관리</h2>
    </div>
    <div class="box one filter">
      <div class="flex">
        <p class="bold">검색조건</p>
        <select v-model="inputMode">
          <option value="" disabled>선택</option>
          <option value="title">설문제목</option>
          <option value="productName">제품명</option>
          <!-- <option value="provider">주최</option>
          <option value="couponInfo">쿠폰정보</option>
          <option value="influencer">인플루언서</option> -->
        </select>
        <input type="text" v-model="searchWord" @keyup.enter="search" />
      </div>
      <div class="flex">
        <p class="bold">카테고리</p>
        <label> <input type="radio" v-model="category" value="" />전체 </label>
        <label>
          <input type="radio" v-model="category" value="SPECIAL" />스페셜
        </label>
        <label>
          <input type="radio" v-model="category" value="TECH" />테크/가전
        </label>
        <label>
          <input type="radio" v-model="category" value="FASHION" />패션/잡화
        </label>
        <label>
          <input type="radio" v-model="category" value="BEAUTY" />뷰티
        </label>
        <label>
          <input type="radio" v-model="category" value="FOOD" />푸드
        </label>
        <label>
          <input type="radio" v-model="category" value="HOMELIVING" />홈리빙
        </label>
        <label>
          <input type="radio" v-model="category" value="TRIP" />여행/레저
        </label>
        <label>
          <input
            type="radio"
            v-model="category"
            value="SPORTS"
          />스포츠/모빌리티
        </label>
        <label>
          <input type="radio" v-model="category" value="CULTURE" />공연/컬쳐
        </label>
        <label>
          <input type="radio" v-model="category" value="KIDS" />베이비/키즈
        </label>
      </div>
      <div class="flex">
        <p class="bold">설문상태</p>
        <label> <input type="radio" v-model="status" value="" />전체 </label>
        <label>
          <input type="radio" v-model="status" value="FINISH" />마감
        </label>
      </div>
      <div class="flex">
        <p class="bold">정렬</p>
        <label>
          <input type="radio" value="date" v-model="sort" />등록일
        </label>
        <label>
          <input type="radio" value="surveyCount" v-model="sort" />참여자 수
        </label>
      </div>
      <div class="buttonWrap">
        <button class="basic" @click="search">검색</button>
        <button class="line" @click="reset">초기화</button>
      </div>
    </div>

    <div class="box one">
      <h2 class="bold">
        설문 리스트
        <button class="flr basic">
          <router-link to="/admin/posts/register">설문 등록</router-link>
        </button>
      </h2>
      <table>
        <colgroup>
          <col style="width: 6%" />
          <col style="width: 12%" />
          <col style="width: 25%" />
          <col style="width: 8%" />
          <col style="width: 8%" />
          <col style="width: 9%" />
          <col style="width: 9%" />
          <col style="width: 13%" />
          <col style="width: 5%" />
          <col style="width: 5%" />
        </colgroup>
        <tr>
          <th>번호</th>
          <th>카테고리</th>
          <!-- <th>제품명</th> -->
          <th>설문제목</th>
          <th>설문시작일</th>
          <th>설문종료일</th>
          <th>참여인원</th>
          <!-- <th>시작가</th> -->
          <th>현재가</th>
          <th>등록일</th>
          <th>설문상태</th>
          <th>관리</th>
        </tr>
        <tr v-for="(data, i) in postList" :key="i">
          <td>
            <router-link to @click.native="handleMove(data._id)">
              {{ total + 1 - (i + 1 + (currentPage - 1) * 10) }}</router-link
            >
          </td>
          <td>
            {{
              data.category == "SPECIAL"
                ? "스페셜"
                : data.category == "TECH"
                ? "테크/가전"
                : data.category == "FASHION"
                ? "패션/잡화"
                : data.category == "BEAUTY"
                ? "뷰티"
                : data.category == "FOOD"
                ? "푸드"
                : data.category == "HOMELIVING"
                ? "홈리빙"
                : data.category == "TRIP"
                ? "여행/레저"
                : data.category == "SPORTS"
                ? "스포츠/모빌리티"
                : data.category == "CULTURE"
                ? "공연/컬쳐"
                : data.category == "KIDS"
                ? "베이비/키즈"
                : ""
            }}
          </td>
          <!-- <td>
            {{ data.productName }}
          </td> -->
          <td>
            <!-- <router-link :to="`/admin/surveys/detail/${data._id}`">{{
              data.title
            }}</router-link> -->
            <router-link to @click.native="handleMove(data._id)">{{
              data.title
            }}</router-link>
          </td>
          <td>
            {{ moment(data.startAt).format("YYYY.MM.DD") }}
          </td>
          <td>
            {{
              data.limitType == "PERIOD"
                ? moment(data.endAt).format("YYYY.MM.DD")
                : "-"
            }}
          </td>
          <td>{{ data.surveyers.length }}명</td>
          <!-- <td>{{ numberFormat(data.startAmount) }}원</td> -->
          <td>{{ numberFormat(Math.round(data.avgAmount)) }}원</td>
          <td>{{ moment(data.createdAt).format("YYYY.MM.DD HH:mm") }}</td>
          <td
            :class="{
              finish: data.status == 'FINISH',
              end:
                (data.limitType == 'PERIOD' &&
                  data.status == '' &&
                  moment() > moment(data.endAt)) ||
                data.status == 'COMPLETE',
            }"
          >
            <span v-if="data.limitType == 'PERIOD'">
              {{
                data.status == "FINISH"
                  ? "마감"
                  : moment() > moment(data.endAt)
                  ? "설문종료"
                  : "진행중"
              }}</span
            >
            <span v-else>
              {{
                data.status == "COMPLETE"
                  ? "설문종료"
                  : data.status == "FINISH"
                  ? "마감"
                  : "진행중"
              }}</span
            >
          </td>
          <td>
            <router-link :to="`/admin/posts/register?id=${data._id}`"
              ><button v-show="data.status != 'FINISH'">
                수정
              </button></router-link
            >
          </td>
        </tr>
      </table>
      <NoData v-if="postList.length == 0" />

      <div class="pagination" v-if="postList.length > 0">
        <el-pagination
          layout="prev, pager, next"
          :total="total"
          :page-size="10"
          @current-change="handleCurrentChange"
        ></el-pagination>
      </div>
    </div>
  </div>
</template>

<script>
import moment from "moment";
import { fetchPostList } from "@/api/admin";
import { format } from "@/mixins/format";
import NoData from "@/components/contents/Nodata";
export default {
  name: "PostManage",
  mixins: [format],
  components: { NoData },
  data() {
    return {
      moment: moment,
      category: "",
      status: "",
      postList: [],
      inputMode: "",
      searchWord: "",
      total: 0,
      currentPage: 1,
      sort: "date",
      loading: false,
    };
  },
  created() {
    this.$store.dispatch("SET_NAVBAR", 2);
  },
  mounted() {
    this.loading = true;
    this.getPostList();
  },
  methods: {
    customFormatter(date) {
      return moment(date).format("YYYY년 MM월 DD일");
    },
    reset() {
      this.inputMode = "";
      this.searchWord = "";
      this.category = "";
      this.status = "";
      this.currentPage = 1;
      this.postList = [];
      this.total = 0;
      this.getPostList();
    },
    search() {
      if (this.searchWord && this.inputMode == "") {
        return alert("검색 조건을 선택을 주세요.");
      } else if (this.inputMode && this.searchWord == "") {
        return alert("검색어를 입력해 주세요.");
      }
      this.currentPage = 1;
      this.getPostList();
    },
    handleMove(id) {
      this.$router.push({ name: "postDetail", params: { id: id } });
    },
    handleCurrentChange(val) {
      this.currentPage = val;
      this.getPostList();
    },
    getPostList() {
      let params = {
        searchWord: this.searchWord,
        inputMode: this.inputMode,
        category: this.category,
        status: this.status,
        sort: this.sort,
        page: this.currentPage,
      };
      fetchPostList(params).then((res) => {
        if (res.data.status == 200) {
          this.postList = res.data.data;
          this.total = res.data.total;
          this.currentPage = res.data.page;
          this.loading = false;
        } else {
          let message = res.data.message;
          alert(message);
        }
      });
    },
  },
};
</script>
